
import { Vue, Component } from "vue-property-decorator";
import { ReceitaParcela } from "../../../core/models/financeiro";
import { ReceitaService } from "../../../core/services/financeiro";
import { ContratoService, PessoaService } from "@/core/services/cadastros";
import { Contrato, Pessoa } from "@/core/models/cadastros";

@Component
export default class ListarParcela extends Vue {
  
  overlay = false;
  filter = { id: null };
  parcela = new ReceitaParcela();
  mostrarCard = false;
  dialogPessoa = false;
  dialogContrato = false;
  pessoa = new Pessoa();
  contrato = new Contrato();
  service = new ReceitaService();
  servicePessoa = new PessoaService();
  serviceContrato = new ContratoService();

  titulo: string = 'Buscar Parcela Por Id';

  filtrarParcela() {
    const idParcela = Number(this.filter.id);

    this.service
      .ObterParcelaPorId(idParcela, "Receita.Cliente, Receita.Contrato.Receitas")
      .then(
        (res) => {
          this.parcela = res.data;
          this.mostrarCard = true;
        },
        (err) => {
          if (!err.response) {
            this.$swal("Aviso", "Não foi possível acessar a API", "error");
          } else if (err.response.status == 403) {
            this.$swal("Aviso", err.response.data.message, "warning");
          } else {
            this.$swal(
              "Aviso",
              err.response.data,
              err.response.status == 400 ? "warning" : "error"
            );
          }
        }
      );
  }

  visualizarPessoa(id: number) {
    this.servicePessoa.ObterPorId(id, "Documentos, Contatos").then(
      (res) => {
      this.pessoa = res.data;
      this.pessoa.dataExpedicao =  new Date(res.data.dataExpedicao).yyyymmdd();
      this.pessoa.dataNascimento = new Date(res.data.dataNascimento).yyyymmdd();
      this.pessoa.conjuge.dataNascimento = res.data.conjuge ?   new Date(res.data.conjuge.dataNascimento).yyyymmdd() : ''
      this.dialogPessoa = true;
      },
      (err) => {
        if (!err.response) {
          this.$swal("Aviso", "Não foi possível acessar a API", "error");
        } else if (err.response.status == 403) {
          this.$swal("Aviso", err.response.data.message, "warning");
        } else {
          this.$swal(
            "Aviso",
            err.response.data,
            err.response.status == 400 ? "warning" : "error"
          );
        }
      }
    );
  }

  visualizarContrato(id: number) {
    this.overlay = !this.overlay;
    this.serviceContrato
      .ObterPorId(id, "Receitas.Parcelas.AntecipacaoAmortizacao.Itens,TipoAmortizacao, Receitas.Parcelas.AntecipacaoComprovante, Observacoes, Receitas.Parcelas.Situacao, Receitas.Parcelas.Baixas.FormaPagamento,Receitas.Tipo, Receitas.Parcelas.Baixas.ReceitaBaixaComprovante, TipoIndice,  HistoricoSituacoes.Situacao, HistoricoSituacoes.Usuario")
      .then(
        (res) => {
          this.contrato = res.data;
          this.dialogContrato = true;
        },
        (err) => {
          if (!err.response) {
            this.$swal("Aviso", "Não foi possível acessar a API", "error");
          } else if (err.response.status == 403) {
            this.$swal("Aviso", err.response.data.message, "warning");
          } else {
            this.$swal(
              "Aviso",
              err.response.data,
              err.response.status == 400 ? "warning" : "error"
            );
          }
        }
      );
  }
}
